@import '../node_modules/swiper/swiper.min.css';
@import '../node_modules/leaflet/dist/leaflet.css';
@import './assets/scss/splash-screen';
@import './assets/scss/helpers/helpers';
@import './assets/scss/mixins/breakpoints';
@import 'app/components/reservation/reservation-style';
@import 'app/components/image-viewer/image-viewer-config';
@import './assets/scss/swiper/swiper-styles';
@import "./assets/scss/theme/material-custom-styles.scss";
@import 'ngx-toastr/toastr';

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  scrollbar-width: thin;
}

body {
  margin: 0;
  font-family: Poppins, "Helvetica Neue", sans-serif;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  font-family: Poppins, serif !important;

  @media (min-width: 800px) and (max-width: 1400px) {
    zoom: 90%;
    -webkit-zoom: 90%;
  }

  button,
  a,
  i,
  mat-icon,
  img,
  p,
  label,
  input,
  textarea {
    outline: none !important;
  }
}

.mat-filter {
  background-color: white !important;
}

.app-dialog-capacity .mat-dialog-container {
  padding: 0 !important;
}

.mat-select-panel,
.mat-dialog-content {
  scrollbar-width: thin;
}

.mat-tab-label {
  padding: 0 24px !important;
  min-width: auto !important;
}

.mat-autocomplete-panel,
.filter-autocomplete {
  scrollbar-width: thin;
  border-radius: 8px;
  min-width: 300px !important;
}

@media (max-width: 800px) {
  .responsive_table {
    overflow-x: auto !important;
    scrollbar-width: thin;
  }

  .mat-table {
    min-width: 600px;
  }
}

.responsive_table {
  overflow-x: auto !important;
  scrollbar-width: thin;
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
  position: relative;
  height: inherit;
  width: 100% !important;
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15) !important;
  z-index: 1024;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-search-filters {
  max-width: 100vw !important;

  .mat-dialog-container {
    padding: 0;
  }
}

.withoutPaddingBottom {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.loading-shade-full {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  z-index: 1024;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.12) !important;
}

.greyIcon {
  color: #616161 !important;
}

.iconHelp {
  font-size: 14px;
  color: $grey-700;
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 12px;
  background-color: rgba(0, 0, 0, 0);
}

.uppercase {
  text-transform: uppercase !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9f9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 24px rgba(0, 0, 0, 0.37);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

::-webkit-scrollbar-thumb:active {
  box-shadow: inset 0 0 0 24px rgba(0, 0, 0, 0.54);
}

.bg-light {
  background-color: #f5f5f5 !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-end {
  text-align: end !important;
}

.tex-align-left {
  text-align: left !important;
}

.underline {
  text-decoration: underline !important;
}

.sec-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}

.filter-row {
  box-sizing: border-box;
  height: 64px !important;
  padding: 8px 25px;
  background: $grey-400;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}

.text-muted {
  color: $grey-400 !important;
}

.mat-ink-bar {
  height: 4px !important;
  border-radius: 2px;
}

.search-input {
  font-size: 17px;
  outline: none;
  padding: 4px 8px 4px 16px;
  /* min-width: 200px; */
  border: 0;
  box-shadow: none;
  background-color: #fcfcfc;
  height: 100%;
  border-radius: 8px;
}

.loader {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  -webkit-animation: 2s ease-in-out infinite progress;
  animation: 2s ease-in-out infinite progress;
  background: #eff1f6 linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0)) no-repeat 0 0;
  border-radius: 4px;
  width: 100%;
  display: inline-block;
}

@-webkit-keyframes progress {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}

@keyframes progress {
  0% {
    background-position: -200px 0;
  }

  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.enabled {
  color: white;
  padding: 2px 8px;
  font-weight: 500;
  box-sizing: border-box;
  background-color: #4caf50;
  border-radius: 4px;
}

.disabled {
  color: white;
  padding: 2px 8px;
  font-weight: 500;
  box-sizing: border-box;
  background-color: #e53935;
  border-radius: 4px;
}

.cancelled {
  color: white;
  padding: 2px 8px;
  font-weight: 500;
  box-sizing: border-box;
  background-color: #e53935;
  border-radius: 4px;
}

.partial-payed {
  color: black !important;
  padding: 2px 8px;
  line-height: normal !important;
  font-weight: 500;
  box-sizing: border-box;
  background-color: #c5e1a5;
  border-radius: 4px;
}

.break-line {
  margin: 0;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  line-height: 1.2rem;
  height: 1.2rem;
  width: 100%;
  overflow: hidden;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $main-color !important;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: $main-color !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar,
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb,
.mat-flat-button.mat-primary,
.mat-toolbar.mat-primary,
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-tab-group.mat-accent .mat-ink-bar,
.mat-tab-nav-bar.mat-accent .mat-ink-bar,
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $main-color !important;
  color: white !important;

  &.mat-button-disabled {
    opacity: 0.2 !important;
  }
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-icon {
  width: 40px !important;
  height: 40px !important;
  margin-top: -10px !important;

  mat-icon {
    font-size: 20px !important;
    height: 20px !important;
    width: 20px !important;
  }
}

.capitalize {
  text-transform: capitalize;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.main-color {
  color: $main-color !important;
}

.color-warn {
  color: $red-color !important;
}

.mat-tab-body-content {
  scrollbar-width: thin;
}

.mat-menu-content {
  min-width: 124px !important;
}
