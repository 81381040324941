#fuse-splash-screen {
  display: block;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  pointer-events: none;
  background-color: #2196f3;
  background-image: url("../images/background_marlin.png");
  background-attachment: fixed;

  .center {
    display: block;
    position: absolute;
    top: 50%;
    left: 0 !important;
    right: 0 !important;
    transform: none !important;
    margin: -120px auto 0 auto !important;
  }

  .logo {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
  }

  .logo img {
    filter: drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2));
  }

  .cssload-container *,
  .cssload-container *:before,
  .cssload-container *:after {
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .cssload-container {
    margin: 10px auto auto auto;
    width: 68px;
    height: 39px;
  }

  .cssload-container > div {
    float: left;
    // background: rgb(39, 154, 255);
    background: white;
    height: 100%;
    width: 5px;
    margin-right: 1px;
    display: inline-block;
  }

  .cssload-container > div {
    position: relative;
    bottom: 0;
    margin-top: 34px;
    height: 5px;
    animation: cssload-wave 1.73s infinite ease-in-out;
    -o-animation: cssload-wave 1.73s infinite ease-in-out;
    -ms-animation: cssload-wave 1.73s infinite ease-in-out;
    -webkit-animation: cssload-wave 1.73s infinite ease-in-out;
    -moz-animation: cssload-wave 1.73s infinite ease-in-out;
  }

  .cssload-container .cssload-shaft1 {
    animation-delay: 0.06s;
    -o-animation-delay: 0.06s;
    -ms-animation-delsplash-screenay: 0.06s;
    -webkit-animation-delay: 0.06s;
    -moz-animation-delay: 0.06s;
  }

  .cssload-container .cssload-shaft2 {
    animation-delay: 0.12s;
    -o-animation-delay: 0.12s;
    -ms-animation-delay: 0.12s;
    -webkit-animation-delay: 0.12s;
    -moz-animation-delay: 0.12s;
  }

  .cssload-container .cssload-shaft3 {
    animation-delay: 0.17s;
    -o-animation-delay: 0.17s;
    -ms-animation-delay: 0.17s;
    -webkit-animation-delay: 0.17s;
    -moz-animation-delay: 0.17s;
  }

  .cssload-container .cssload-shaft4 {
    animation-delay: 0.23s;
    -o-animation-delay: 0.23s;
    -ms-animation-delay: 0.23s;
    -webkit-animation-delay: 0.23s;
    -moz-animation-delay: 0.23s;
  }

  .cssload-container .cssload-shaft5 {
    animation-delay: 0.29s;
    -o-animation-delay: 0.29s;
    -ms-animation-delay: 0.29s;
    -webkit-animation-delay: 0.29s;
    -moz-animation-delay: 0.29s;
  }

  .cssload-container .cssload-shaft6 {
    animation-delay: 0.35s;
    -o-animation-delay: 0.35s;
    -ms-animation-delay: 0.35s;
    -webkit-animation-delay: 0.35s;
    -moz-animation-delay: 0.35s;
  }

  .cssload-container .cssload-shaft7 {
    animation-delay: 0.4s;
    -o-animation-delay: 0.4s;
    -ms-animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
  }

  .cssload-container .cssload-shaft8 {
    animation-delay: 0.46s;
    -o-animation-delay: 0.46s;
    -ms-animation-delay: 0.46s;
    -webkit-animation-delay: 0.46s;
    -moz-animation-delay: 0.46s;
  }

  .cssload-container .cssload-shaft9 {
    animation-delay: 0.52s;
    -o-animation-delay: 0.52s;
    -ms-animation-delay: 0.52s;
    -webkit-animation-delay: 0.52s;
    -moz-animation-delay: 0.52s;
  }

  .cssload-container .cssload-shaft10 {
    animation-delay: 0.58s;
    -o-animation-delay: 0.58s;
    -ms-animation-delay: 0.58s;
    -webkit-animation-delay: 0.58s;
    -moz-animation-delay: 0.58s;
  }

  .cssload-container .shaft1 {
    animation-delay: -1.73s;
    -o-animation-delay: -1.73s;
    -ms-animation-delay: -1.73s;
    -webkit-animation-delay: -1.73s;
    -moz-animation-delay: -1.73s;
  }

  .cssload-container .shaft2 {
    animation-delay: -1.61s;
    -o-animation-delay: -1.61s;
    -ms-animation-delay: -1.61s;
    -webkit-animation-delay: -1.61s;
    -moz-animation-delay: -1.61s;
  }

  .cssload-container .shaft3 {
    animation-delay: -1.5s;
    -o-animation-delay: -1.5s;
    -ms-animation-delay: -1.5s;
    -webkit-animation-delay: -1.5s;
    -moz-animation-delay: -1.5s;
  }

  .cssload-container .shaft4 {
    animation-delay: -1.38s;
    -o-animation-delay: -1.38s;
    -ms-animation-delay: -1.38s;
    -webkit-animation-delay: -1.38s;
    -moz-animation-delay: -1.38s;
  }

  .cssload-container .shaft5 {
    animation-delay: -1.27s;
    -o-animation-delay: -1.27s;
    -ms-animation-delay: -1.27s;
    -webkit-animation-delay: -1.27s;
    -moz-animation-delay: -1.27s;
  }

  .cssload-container .shaft6 {
    animation-delay: -1.15s;
    -o-animation-delay: -1.15s;
    -ms-animation-delay: -1.15s;
    -webkit-animation-delay: -1.15s;
    -moz-animation-delay: -1.15s;
  }

  .cssload-container .shaft7 {
    animation-delay: -1.04s;
    -o-animation-delay: -1.04s;
    -ms-animation-delay: -1.04s;
    -webkit-animation-delay: -1.04s;
    -moz-animation-delay: -1.04s;
  }

  .cssload-container .shaft8 {
    animation-delay: -0.92s;
    -o-animation-delay: -0.92s;
    -ms-animation-delay: -0.92s;
    -webkit-animation-delay: -0.92s;
    -moz-animation-delay: -0.92s;
  }

  .cssload-container .shaft9 {
    animation-delay: -0.81s;
    -o-animation-delay: -0.81s;
    -ms-animation-delay: -0.81s;
    -webkit-animation-delay: -0.81s;
    -moz-animation-delay: -0.81s;
  }

  .cssload-container .shaft10 {
    animation-delay: -0.69s;
    -o-animation-delay: -0.69s;
    -ms-animation-delay: -0.69s;
    -webkit-animation-delay: -0.69s;
    -moz-animation-delay: -0.69s;
  }

  .cssload-container .shaft11 {
    animation-delay: -0.58s;
    -o-animation-delay: -0.58s;
    -ms-animation-delay: -0.58s;
    -webkit-animation-delay: -0.58s;
    -moz-animation-delay: -0.58s;
  }

  @keyframes cssload-wave {
    50% {
      height: 100%;
      margin-top: 0;
      // background: rgb(0, 238, 255);
      background: white;
    }
  }

  @-o-keyframes cssload-wave {
    50% {
      height: 100%;
      margin-top: 0;
      // background: rgb(0, 238, 255);
      background: white;
    }
  }

  @-ms-keyframes cssload-wave {
    50% {
      height: 100%;
      margin-top: 0;
      // background: rgb(0, 238, 255);
      background: white;
    }
  }

  @-webkit-keyframes cssload-wave {
    50% {
      height: 100%;
      margin-top: 0;
      // background: rgb(0, 238, 255);
      background: white;
    }
  }

  @-moz-keyframes cssload-wave {
    50% {
      height: 100%;
      margin-top: 0;
      // background: rgb(0, 238, 255);
      background: white;
    }
  }
}
